import $ from 'jquery'
import whatInput from 'what-input'
import Bowser from "bowser"

import './foundation'
import './custom-select'
import { customOCTrigger } from  './off-canvas-custom-trigger'

const bowser = Bowser.parse(window.navigator.userAgent)



try {
    window.$ = window.jQuery = require('jquery')
    
    require('foundation-sites')
    
    $(function() {
        $(document).foundation()
        customOCTrigger($);
    });
    
} catch (e) {}


document.addEventListener("DOMContentLoaded", function(){
    document.body.classList.add(`browser-${bowser.browser.name.toLowerCase()}`)
    document.body.classList.add(`os-${bowser.os.name.toLowerCase()}`)
    document.body.classList.add(`platform-${bowser.platform.type.toLowerCase()}`)
});

const customOCTrigger = ($) => {
    const linkList = document.querySelectorAll('.off-canvas a')
        
    linkList.forEach(link => {
        console.log("before listener add")
        link.addEventListener("click", event => {
            $('#offCanvas').foundation('toggle', event, link);
        })
    })
}


exports.customOCTrigger = customOCTrigger